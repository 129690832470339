<template>
  <div>
    <a-row v-if="dataPengajuanCuti.guru && dataPengajuanCuti.cuti" :gutter="16">
      <a-col :span="24">
        <a-form-item label="Nama Pegawai">
            <a-input :disabled="disabled" v-model="dataPengajuanCuti.guru.nama" size="large" />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Nama Cuti">
            <a-input :disabled="disabled" v-model="dataPengajuanCuti.cuti.nama" size="large" />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Regulasi Cuti">
          <a-select :disabled="disabled" size="large" class="w-100" :value="dataPengajuanCuti.cuti.regulasi" @change="(e) => handleChange(e, 'regulasi')">
            <a-select-option v-for="(item, index) in daftarRegulasi" :key="index" :value="item.nama">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Tanggal Pengajuan">
          <a-date-picker
            :disabled="disabled"
            :value="dataPengajuanCuti.createdAt"
            :disabled-date="disabledEndDate"
            format="YYYY-MM-DD HH:mm"
            :open="endOpen"
            @openChange="handleEndOpenChange"
            class="w-100"
            size="large"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tanggal Awal">
            <a-date-picker
              :disabled="disabled"
              :value="dataPengajuanCuti.tanggal_awal"
              :disabled-date="disabledStartDate"
              format="YYYY-MM-DD"
              @openChange="handleStartOpenChange"
              @change="(e) => handleChange(e, 'tanggal_awal')"
              class="w-100"
              size="large"
              placeholder=""
            />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tanggal Akhir">
            <a-date-picker
            :disabled="disabled"
            :value="dataPengajuanCuti.tanggal_akhir"
            :disabled-date="disabledEndDate"
            format="YYYY-MM-DD"
            :open="endOpen"
            @openChange="handleEndOpenChange"
            @change="(e) => handleChange(e, 'tanggal_akhir')"
            class="w-100"
            size="large"
            placeholder=""
            />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Keterangan Tambahan">
          <a-textarea
            :disabled="disabled"
            v-model="dataPengajuanCuti.keterangan_tambahan"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="File Tambahan">
          <div class="rounded border">
            <template v-if="dataPengajuanCuti.files.length">
              <div v-for="item in dataPengajuanCuti.files" :key="item.id" class="d-flex item">
                <div class="ml-3">
                  <a-icon class="text-warning" type="file" style="font-size:20px;" />
                </div>
                <div class="ml-3">
                  <p>{{item.originalname}}</p>
                </div>
                <div class="ml-auto mr-3">
                  <a target="_blank" :href="`${config.apiUrl}/${item.filepath}`"><p class="text-primary text-uppercase">Download</p></a>
                </div>
              </div>
            </template>
           <template v-else>
              <div v-if="dataPengajuanCuti.status_pengajuan !== 'terima'" style="d-flex justify-content-center align-items-center">
                <a-empty class="p-4" />
              </div>
           </template>
          </div>
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import config from '@/config'
const daftarTipeCuti = [
  {
    nama: 'cuti',
  },
  {
    nama: 'lain-lain',
  },
]
const daftarRegulasi = [
  {
    nama: 'Dibayar',
  },
  {
    nama: 'Tidak Dibayar',
  },
]
export default {
  props: {
    dataPengajuanCuti: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      config,
      daftarTipeCuti,
      daftarRegulasi,
      endOpen: false,
    }
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.dataPengajuanCuti.tanggal_akhir
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.dataPengajuanCuti.tanggal_awal
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style lang="scss">
@import '@/components/mixins.scss';

.item {
  border-bottom: 1px solid $gray-2;
  padding: 5px 0;

  &:last-child {
    border-bottom: none;
  }
}

</style>
